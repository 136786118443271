import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { AuthContext } from '../../context/authContext';
import { logoutUser, getRewards, toggleReward } from '../../utilities/sendRequest';
import { dateTimeIndo } from '../../utilities/helper';

const Rewards = () => {
    const { dispatch } = useContext(AuthContext)
    const [datas, setDatas] = useState([])
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetchEmployees()
        setFetchMore(false)
        // eslint-disable-next-line
    }, [fetchMore])

    const fetchEmployees = async () => {
        getRewards().then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 2500)
            }
        })
    }

    const handleToggle = (id) => {
        toggleReward(id).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const columns = [
        { 
            field: 'title', 
            headerName: 'Title',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.title}
                    </div>
                )
            } 
        },
        { 
            field: 'point', 
            headerName: 'Point',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.point}
                    </div>
                )
            } 
        },
        { 
            field: 'published_at', 
            headerName: 'Published At',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {dateTimeIndo(params.row.published_at)}
                    </div>
                )
            } 
        },
        { 
            field: 'expired_at', 
            headerName: 'Expired At',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {dateTimeIndo(params.row.expired_at)}
                    </div>
                )
            } 
        },
        { 
            field: 'Author.firstname', 
            headerName: 'Author',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Author.firstname}
                    </div>
                )
            } 
        },
        {
            field: 'is_active',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <Link to={"/rewards/" + params.row.id}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                        {params.row.is_active
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                        }
                    </div>
                )
            }
        },
    ]

    return (
        <div className={`min-h-[calc(100vh-4rem)] flex flex-col`}>
            {/* header  */}
            <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Rewards</h1>
                <Link to={'/rewards/add'} className="px-5 py-2.5 mr-3 mt-3 md:mt-1 bg-blue-400 hover:bg-blue-500 rounded-lg flex text-sm font-medium self-start md:self-end cursor-pointer">
                    <span className='text-sm text-white font-ssp'>Add New</span>
                </Link>
            </div>
            {/* content  */}
            <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={5}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Rewards