import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from 'react-icons/io';
import { getEchelons, getEmployee, getOffices, getPositions, resetPasswordEmployee, updateEmployee } from '../../utilities/sendRequest';
import CardPhones from '../../components/Employees/cardPhones';
import ModalPhoneDetail from '../../components/Employees/modalPhone';
import CardAddress from '../../components/Employees/cardAddress';
import CardPoints from '../../components/Employees/cardPoints';
import ModalUpdatePoint from '../../components/Employees/modalUpdatePoint';
import ModalPointDetail from '../../components/Employees/modalDetailPoint';
import CardFamilies from '../../components/Employees/cardFamilies';
import ModalFamilyDetail from '../../components/Employees/modalFamily';
import ModalAddressDetail from '../../components/Employees/modalAddres';
import CardFamilyPhones from '../../components/Employees/cardFamilyPhones';
import ModalFamilyPhoneDetail from '../../components/Employees/modalFamilyPhone';
import CardFamilyAddress from '../../components/Employees/cardFamilyAddress';
import ModalFamilyAddressDetail from '../../components/Employees/modalFamilyAddres';

const DetailEmployee = () => {
    const params = useParams()
    const photoRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)
    const [isReadyReset, setIsReadyReset] = useState(true)
    const [modalData, setModalData] = useState([])
    const [fetchAgain, setFetchAgain] = useState(false)


    const [showModalUpdatePoint, setShowModalUpdatePoint] = useState(false)
    const [showModalDetailPoint, setShowModalDetailPoint] = useState(false)
    const [showModalPhone, setShowModalPhone] = useState(false)
    const [showModalFamilyPhone, setShowModalFamilyPhone] = useState(false)
    const [showModalAddress, setShowModalAddress] = useState(false)
    const [showModalFamilyAddress, setShowModalFamilyAddress] = useState(false)
    const [showModalFamily, setShowModalFamily] = useState(false)

    const [photo, setPhoto] = useState("")
    const [data, setData] = useState({})
    const [nip, setNip] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [birthday, setBirthday] = useState("")
    const [hireDate, setHireDate] = useState("")
    const [hometown, setHometown] = useState("")
    const [religion, setReligion] = useState("")
    const religionOptions = [
        {
            label: "ISLAM",
            value: "ISLAM"
        },
        {
            label: "KRISTEN",
            value: "KRISTEN"
        },
        {
            label: "KATOLIK",
            value: "KATOLIK"
        },
        {
            label: "HINDU",
            value: "HINDU"
        },
        {
            label: "BUDDHA",
            value: "BUDDHA"
        },
        {
            label: "KHONGHUCU",
            value: "KHONGHUCU"
        },
    ]
    const [lastEducation, setLastEducation] = useState("")
    const [job, setJob] = useState("")
    const [marital, setMarital] = useState("")
    const maritalOptions = [
        {
            label: "SINGLE",
            value: "SINGLE",
        },
        {
            label: "MARRIED",
            value: "MARRIED"
        },
        {
            label: "DIVORCE",
            value: "DIVORCE"
        },
    ]
    const [gender, setGender] = useState("")
    const genderOptions = [
        {
            label: "PRIA",
            value: "PRIA"
        },
        {
            label: "WANITA",
            value: "WANITA"
        },
    ]
    const [office, setOffice] = useState("")
    const [offices, setOffices] = useState([])
    const [echelon, setEchelon] = useState("")
    const [echelons, setEchelons] = useState([])
    const [position, setPosition] = useState("")
    const [positions, setPositions] = useState([])
    const [status, setStatus] = useState("")
    const statusOptions = [
        {
            label: "ACTIVE",
            value: true
        },
        {
            label: "INACTIVE",
            value: false
        },
    ]
    
    useEffect(() => {
        fetchOffices()
        fetchEchelons()
        fetchPositions()
        fetchEmployee()
        setFetchMore(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore])
    
    useEffect(() => {
        const isReady = (firstname !== "" && religion !== "" && marital !== "" && office !== "" && gender !== "" && echelon !== "" && position !== "" && status !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstname, religion, marital, office, gender, echelon, position, status])

    const fetchEmployee = async () => {
        getEmployee(params.nip).then((res) => {
            setData(res.payload);
            
            setFirstname(res.payload.firstname)
            setLastname(res.payload.lastname)
            setEmail(res.payload.email)
            setBirthday(res.payload.Biodata.birthday)
            setHireDate(res.payload.Biodata.hire_date)
            setHometown(res.payload.Biodata.hometown)
            setJob(res.payload.Biodata.job)
            setReligion(res.payload.Biodata.religion)
            setLastEducation(res.payload.Biodata.last_education)
            setMarital(res.payload.Biodata.marital_status)
            setGender(res.payload.Biodata.gender)

            setEchelon(res.payload.Biodata.Echelon.code)
            setOffice(res.payload.Biodata.Office.slug)
            setPosition(res.payload.Biodata.Position.slug)
            setNip(res.payload.nip)
            setStatus(res.payload.is_active)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchOffices = async () => {
        getOffices().then((res) => {
            setOffices(res.payload.filter(office => office.is_active === true))
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchEchelons = async () => {
        getEchelons().then((res) => {
            setEchelons(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchPositions = async () => {
        getPositions().then((res) => {
            setPositions(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = new FormData();
        reqData.append("nip", nip);
        reqData.append("firstname", firstname);
        reqData.append("lastname", lastname);
        reqData.append("email", email);
        reqData.append("birthday", birthday);
        reqData.append("hire_date", hireDate);
        reqData.append("hometown", hometown);
        reqData.append("religion", religion);
        reqData.append("last_education", lastEducation);
        reqData.append("job", job);
        reqData.append("marital_status", marital);
        reqData.append("gender", gender);
        reqData.append("office_slug", office);
        reqData.append("echelon_code", echelon);
        reqData.append("position_slug", position);
        reqData.append("is_active", status);

        if(photo) {
            reqData.append("photo", photo);
        }

        updateEmployee(params.nip, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchEmployee()
            handleResetPreview()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const handleResetPassword = () => {
        setIsReadyReset(false)
        resetPasswordEmployee(data.email).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchEmployee()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReadyReset(true)
        })
    }

    const handleResetPreview = () => {
        setPhoto(null)
        photoRef.current.value = null;
    }


    return (
        <>
            {showModalUpdatePoint && 
                <ModalUpdatePoint setShowModalUpdatePoint={setShowModalUpdatePoint} modalData={modalData} setFetchMore={setFetchMore} />
            }
            {showModalDetailPoint && 
                <ModalPointDetail setShowModalDetailPoint={setShowModalDetailPoint} modalData={modalData} employeeData={data} />
            }
            {showModalPhone && 
                <ModalPhoneDetail setShowModalPhone={setShowModalPhone} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            {showModalAddress && 
                <ModalAddressDetail setShowModalAddress={setShowModalAddress} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            {showModalFamily && 
                <ModalFamilyDetail setShowModalFamily={setShowModalFamily} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            {showModalFamilyPhone && 
                <ModalFamilyPhoneDetail setShowModalFamilyPhone={setShowModalFamilyPhone} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            {showModalFamilyAddress && 
                <ModalFamilyAddressDetail setShowModalFamilyAddress={setShowModalFamilyAddress} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${(showModalUpdatePoint || showModalDetailPoint || showModalPhone || showModalAddress || showModalFamily || showModalFamilyPhone || showModalFamilyAddress) && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header */}
                <div className="my-5 mx-7 md:mx-5 flex flex-row flex-wrap gap-5 justify-between">
                    <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Employee | {data.nip}</h1>
                    <button type='button'
                        onClick={handleResetPassword}
                        disabled={!isReadyReset}
                        className={`px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-red-400 hover:bg-red-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg
                        font-medium text-sm text-center`}
                    >RESET PASSWORD</button>
                </div>
                {/* content */}
                <div className="my-5 mx-7 md:mx-5 flex flex-col gap-10">
                    {/* photo profile */}
                    <div className='w-full flex flex-col md:flex-row justify-start gap-5'>
                        {/* <div className="px-7 py-5 w-full md:w-[30%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                            <span className='text-center font-semibold text-2xl tracking-wider'>Point</span>
                            <hr />
                            <div className='mt-3 w-52 h-52 p-2 bg-slate-900 rounded-full drop-shadow-lg font-extrabold text-white text-8xl text-center self-center flex justify-center items-center' >
                                56
                            </div>
                            <div className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-blue-400 hover:bg-blue-500 rounded-lg 
                                                font-medium text-sm text-white text-center cursor-pointer"
                            >Update Point</div>
                        </div> */}
                        <div className="px-7 py-5 w-full md:w-[30%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                            <span className='text-center font-semibold text-2xl tracking-wider'>Profile</span>
                            <hr />
                            <div className='mt-3 w-full drop-shadow-lg flex justify-center'>
                                {data.photo
                                ? (<img src={`${process.env.REACT_APP_PUBLIC_FOLDER_IMAGES}/${data.photo}`} alt={data.photo} className='w-full h-full object-cover'/>)
                                : (
                                    <div className='bg-gray-800 w-1/2 p-5 rounded-full'>
                                        <img src={'/assets/icons/Enterprisers.svg'} alt={data.photo} className='w-full h-full object-cover'/>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                    {/* form update */}
                    <div className="px-2 py-4 w-full flex bg-white drop-shadow-lg rounded-md">
                        <form onSubmit={handleUpdate} className='px-5 py-3 w-full flex flex-wrap justify-between'>
                            {/* firstname */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    minLength={3} 
                                    value={firstname} 
                                    onChange={(e) => setFirstname(e.target.value)} 
                                    type="text" 
                                    name="firstname" 
                                    id="firstname" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="firstname" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Firstname</label>
                            </div>
                            {/* lastname */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    value={lastname} 
                                    onChange={(e) => setLastname(e.target.value)} 
                                    type="text" 
                                    name="lastname" 
                                    id="lastname" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="lastname" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Lastname</label>
                            </div>
                            {/* email */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                    type="email" 
                                    name="email" 
                                    id="email" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="email" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Email</label>
                            </div>
                            {/* birthday */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    value={birthday} 
                                    onChange={(e) => setBirthday(e.target.value)} 
                                    type="date" 
                                    name="birthday" 
                                    id="birthday" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="birthday" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Birthday</label>
                            </div>
                            {/* hometown */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    value={hometown} 
                                    onChange={(e) => setHometown(e.target.value)} 
                                    type="text" 
                                    name="hometown" 
                                    id="hometown" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="hometown" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Kota Kelahiran</label>
                            </div>
                            {/* hireDate */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    value={hireDate} 
                                    onChange={(e) => setHireDate(e.target.value)} 
                                    type="date" 
                                    name="hireDate" 
                                    id="hireDate" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="hireDate" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Tanggal Masuk Kerja</label>
                            </div>
                            {/* lastEducation */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    value={lastEducation} 
                                    onChange={(e) => setLastEducation(e.target.value)} 
                                    type="text" 
                                    name="lastEducation" 
                                    id="lastEducation" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="lastEducation" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Pendidikan Terakhir</label>
                            </div>
                            {/* job */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <input 
                                    value={job} 
                                    onChange={(e) => setJob(e.target.value)} 
                                    type="text" 
                                    name="job" 
                                    id="job" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                                appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" " 
                                />
                                <label 
                                    htmlFor="job" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Job</label>
                            </div>
    
                            {/* religion */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <select 
                                    required 
                                    value={religion} 
                                    onChange={(e) => setReligion(e.target.value)} 
                                    id="religion_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose Religion</option>
                                    {religionOptions.map((option) => (
                                        <option key={option.label} value={option.value}>
                                            {option.label} 
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="religion_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Religion</label>
                            </div>
                            {/* gender */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <select 
                                    required 
                                    value={gender} 
                                    onChange={(e) => setGender(e.target.value)} 
                                    id="gender_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose Gender</option>
                                    {genderOptions.map((option) => (
                                        <option key={option.label} value={option.value}>
                                            {option.label} 
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="gender_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Gender</label>
                            </div>
                            {/* marital */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <select 
                                    required 
                                    value={marital} 
                                    onChange={(e) => setMarital(e.target.value)} 
                                    id="marital_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose Marital</option>
                                    {maritalOptions.map((option) => (
                                        <option key={option.label} value={option.value}>
                                            {option.label} 
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="marital_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Marital</label>
                            </div>
                            {/* office */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <select 
                                    required 
                                    value={office} 
                                    onChange={(e) => setOffice(e.target.value)} 
                                    id="office_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose Office</option>
                                    {offices.map((option) => (
                                        <option key={option.slug} value={option.slug}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="office_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Office</label>
                            </div>
                            {/* echelon */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <select 
                                    required 
                                    value={echelon} 
                                    onChange={(e) => setEchelon(e.target.value)} 
                                    id="echelon_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose Echelon</option>
                                    {echelons.map((option) => (
                                        <option key={option.code} value={option.code}>
                                            {option.title} 
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="echelon_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Echelon</label>
                            </div>
                            {/* position */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <select 
                                    required 
                                    value={position} 
                                    onChange={(e) => setPosition(e.target.value)} 
                                    id="position_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose Position</option>
                                    {positions.map((option) => (
                                        <option key={option.slug} value={option.slug}>
                                            {option.title} 
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="position_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Position</label>
                            </div>
                            {/* status */}
                            <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                                <select required value={status} 
                                    onChange={(e) => setStatus(e.target.value)} 
                                    id="status_select" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                >
                                    <option value="">Choose a status</option>
                                    {statusOptions.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="status_select" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Status</label>
                            </div>
                            {/* photo */}
                            <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                                <label 
                                    className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                    htmlFor="file_thumbnail"
                                >Upload New Photo</label>
                                <input 
                                    type="file" 
                                    name="photo"
                                    ref={photoRef}
                                    onChange={(e) => setPhoto(e.target.files[0])}
                                    accept='.png,.jpg,.jpeg'
                                    className="block w-full text-sm text-slate-500
                                                file:mr-4 file:py-2 file:px-4
                                                file:rounded-full file:border-0
                                                file:text-sm file:font-semibold
                                                file:bg-gray-50 file:text-gray-500
                                                hover:file:bg-gray-100"
                                />
                                <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_photo_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                            
                            </div>
                            {photo && 
                                <div className="mt-3 w-full relative flex flex-row group">
                                    <img src={URL.createObjectURL(photo)} alt="Preview" className="max-w-[300px] max-h-[200px] object-cover" />
                                    <IoIosCloseCircle 
                                        fontSize='25px' 
                                        className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                        onClick={() => handleResetPreview()} />
                                </div>
                            }
                            {/* button */}
                            <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                                <button 
                                    type="submit" 
                                    disabled={!isReady}
                                    className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                                >Update</button>
                                <Link 
                                    to={'/employees'} 
                                    className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                                font-medium text-sm text-white text-center cursor-pointer"
                                >Back</Link>
                            </div>
                        </form>
                    </div>
                    {/* point */}
                    <CardPoints data={data} setModalData={setModalData} setShowModalUpdatePoint={setShowModalUpdatePoint} setShowModalDetailPoint={setShowModalDetailPoint} />
                    {/* phones */}
                    <CardPhones nip={params.nip} setModalData={setModalData} setShowModalPhone={setShowModalPhone} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                    {/* address */}
                    <CardAddress nip={params.nip} setModalData={setModalData} setShowModalAddress={setShowModalAddress} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                    {/* family */}
                    <CardFamilies nip={params.nip} setModalData={setModalData} setShowModalFamily={setShowModalFamily} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                    {/* family phones */}
                    <CardFamilyPhones nip={params.nip} setModalData={setModalData} setShowModalFamilyPhone={setShowModalFamilyPhone} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                    {/* family address */}
                    <CardFamilyAddress nip={params.nip} setModalData={setModalData} setShowModalFamilyAddress={setShowModalFamilyAddress} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                </div>
            </div>
        </>
    )
}

export default DetailEmployee