import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { RiCloseFill } from "react-icons/ri";
import { IoIosCloseCircle } from "react-icons/io";
import { updatePostGallery } from '../../utilities/sendRequest';
import { PopupContext } from '../../context/popupContext';

const ModalGalleryDetail = ({ setShowModalGallery, modalData, setFetchAgain }) => {
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const photoRef = useRef(null); 
    const [photo, setPhoto] = useState(null)
    const [isReady, setIsReady] = useState(false)
    const [title, setTitle] = useState(modalData.title)

    useEffect(() => {
        const isReady = (title);
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalGallery(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = new FormData();
        reqData.append("title", title);
        
        if (photo) {
            reqData.append("file", photo);
        }

        updatePostGallery(modalData.id, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
            
            setFetchAgain(true)
            setShowModalGallery(null)
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleModal = () => {
        setShowModalGallery(null)
        popupDispatch({ type: "POPUP_END" });
    }

    const handleResetPreview = (id) => {
        setPhoto(null)
        photoRef.current.value = null;
    }

    return (
        <div  className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className='font-bold font-ssp text-xl md:text-2xl'>Detail Gallery</h3>
                    <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                </div>
                <form onSubmit={handleSubmit} className='mt-5 px-5 w-full flex flex-col'>
                    {/* title */}
                    <div className="mt-3 mb-6 w-full relative z-0 group flex justify-center">
                        <div className='w-[200px] h-[100px]'>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_IMAGES}/${modalData.path}`} alt={modalData.title} className='w-full h-full object-contain' />
                        </div>
                    </div>
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <input 
                            minLength={5} 
                            value={title} 
                            onChange={(e) => setTitle(e.target.value)} 
                            type="text" 
                            name="title" 
                            id="title" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer" 
                            placeholder=" " 
                            required
                        />
                        <label 
                            htmlFor="title" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Title</label>
                    </div>
                    {/* photo */}
                    <div className="mb-3 mt-3 w-full relative z-0 group">
                        <label 
                            className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                            htmlFor="file_input2"
                        >Upload Photo</label>
                        <input 
                            type="file" 
                            name="photo"
                            ref={photoRef}
                            onChange={(e) => setPhoto(e.target.files[0])}
                            accept='.png, .jpg, .jpeg'
                            className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                        />
                        <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_input2_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                    </div>
                    {photo && 
                        <div className="mt-3 w-full relative flex flex-row group">
                            <img src={URL.createObjectURL(photo)} alt="Preview" className="w-1/4" />
                            <IoIosCloseCircle  fontSize='25px'
                                className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer'
                                onClick={() => handleResetPreview()} />
                        </div>
                    }
                    {/* Button */}
                    <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                        <button type="submit" 
                            disabled={!isReady}
                            className={`mt-5 px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg text-sm
                                        text-center font-medium`}
                        >Save</button>
                        <button onClick={handleModal} 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                        text-center font-medium cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalGalleryDetail.propTypes = {
    modalData: PropTypes.object,
    setShowModalGallery: PropTypes.func,
    setFetchAgain: PropTypes.func,
}

export default ModalGalleryDetail