import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AuthContext } from '../../context/authContext';
import { PopupContext } from "../../context/popupContext";
import { logoutUser, getEchelons, addEchelon } from '../../utilities/sendRequest';
import ModalEchelonDetail from '../../components/Echelons/modalEchelon';

const Echelons = () => {
    const { dispatch } = useContext(AuthContext)
    const { popupDispatch } = useContext(PopupContext)
    const [datas, setDatas] = useState([])
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [fetchMore, setFetchMore] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const [title, setTitle] = useState("")
    const [code, setCode] = useState("")
    const [echelon, setEchelon] = useState(null)

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    
    const [convertedContent, setConvertedContent] = useState(null);
    
    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    useEffect(() => {
        const isReady = (title, code);
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, code])

    useEffect(() => {
        fetchEchelons()
        setFetchMore(false)

        // eslint-disable-next-line
    }, [fetchMore])

    const fetchEchelons = async () => {
        getEchelons().then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt)
                })
            )
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (err.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 2500)
            }
        })
    }

    const handleCreate = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = {
            title,
            code,
            description: convertedContent
        }

        addEchelon(reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            resetForm()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const resetForm = () => {
        setTitle("")
        setCode("")
        setEditorState(() => EditorState.createEmpty())
    }

    const handleModal = (data) => {
        setShowModalDetail(true)
        setEchelon(data)
        popupDispatch({ type: "POPUP_START" });
    }

    const columns = [
        { 
            field: 'title', 
            headerName: 'Title',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.title}
                    </div>
                )
            } 
        },
        { 
            field: 'code', 
            headerName: 'Code',
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.code}
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row items-center gap-3'>
                        <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer" onClick={() => handleModal(params.row)}/>
                    </div>
                )
            }
        },
    ]

    return (
        <>
            {showModalDetail && <ModalEchelonDetail echelon={echelon} setShowModalDetail={setShowModalDetail} setFetchMore={setFetchMore} />}
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${showModalDetail && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Echelons</h1>
                </div>
                {/* content  */}
                <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                    {/* list data */}
                    <div className='w-full h-full lg:w-[50%] flex flex-col gap-3 items-center'>
                        <div className="p-2 w-full bg-white drop-shadow-lg rounded-md flex">
                            <DataGrid
                                autoHeight {...datas}
                                rows={datas}
                                columns={columns}
                                pageSize={5}
                                getRowId={(row) => row.code}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectionOnClick
                                components={{ Toolbar: GridToolbar, }}
                            />
                        </div>
                    </div>

                    {/* form store */}
                    <div className="px-2 py-4 w-full h-fit lg:w-[50%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                        <h1 className='font-semibold text-base font-ssp px-3'>Create New Echelons</h1>
                        <hr className='px-3'/>
                        <div className=" py-1 w-full h-fit flex">
                            <form onSubmit={handleCreate} className='px-5 w-full flex flex-col justify-between'>
                                {/* title */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <input 
                                        value={title} 
                                        onChange={(e) => setTitle(e.target.value)} 
                                        type="text" 
                                        name="title" 
                                        id="title" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="title" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 
                                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                    peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                    peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Title</label>
                                </div>
                                {/* code */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <input 
                                        value={code} 
                                        onChange={(e) => setCode(e.target.value)} 
                                        type="text" 
                                        name="code" 
                                        id="code" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="code" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 
                                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                    peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                    peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Code</label>
                                </div>
                                {/* description */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <label 
                                        htmlFor="description" 
                                        className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Description</label>
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                        wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                                        toolbarClassName="sticky"
                                        editorClassName="px-2 overflow-y-scroll"
                                    />
                                </div>
                                {/* button */}
                                <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                                    <button 
                                        type="submit" disabled={!isReady}
                                        className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                                    >Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Echelons