import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { addOfficePhone, deleteOfficePhone, getOfficePhones } from '../../utilities/sendRequest';
import { codeOptions, mainOptions } from '../../utilities/selectOptions';

const CardOfficePhones = ({ slug, setModalData, setShowModalPhone, fetchAgain, setFetchAgain }) => {
    const [isReady, setIsReady] = useState(false)
    const [fetchMore, setFetchMore] = useState(false)

    const [phone, setPhone] = useState("")
    const [status, setStatus] = useState("")
    const [code, setCode] = useState("")
    const [datas, setDatas] = useState([])

    useEffect(() => {
        fetchPhones();
        setFetchMore(false);
        setFetchAgain(false);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore, fetchAgain])

    useEffect(() => {
        const isReady = (phone !== "" && status !== "" && code !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone, status, code])

    const fetchPhones = async () => {
        getOfficePhones(slug).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt)
                })
            )
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const columns = [
        { 
            field: 'code', 
            headerName: 'Code',
            width: 70, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Phone.code}
                    </div>
                )
            } 
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.Phone.phone_number}
                    </div>
                )
            } 
        },
        {
            field: 'is_main',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.Phone.is_main
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">UTAMA</span>
                            : <span className="px-2 py-1 bg-gray-500 rounded-full text-white uppercase">Opsional</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <div onClick={() => handleModal(params.row)}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </div>
                        <DeleteIcon fontSize='medium' className="text-red-400 hover:text-red-500 cursor-pointer" onClick={() => handleDelete(params.row.id)}/>
                        
                    </div>
                )
            }
        },
    ]

    const handleDelete = async (paramsId) => {
        deleteOfficePhone(paramsId).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setFetchMore(true)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        setIsReady(false)

        const reqData = {
            code,
            phone_number: phone,
            is_main: status
        }

        addOfficePhone(slug, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setFetchMore(true)
            handleReset();
            setIsReady(true)
        })
    }

    const handleReset = () => {
        setPhone("")
        setCode("")
        setStatus("")
    }

    const handleModal = (data) => {
        setModalData(data)
        setShowModalPhone(true)
    }

    return (
        <div className='my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10'>
            {/* list data */}
            <div className='w-full h-full lg:w-[60%] flex flex-col gap-3 items-center'>
                <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={5}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>

            {/* form create */}
            <div className="px-2 py-4 w-full h-fit lg:w-[40%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                <h1 className='font-semibold text-base font-ssp px-3'>Add Phones Office</h1>
                <hr className='px-3'/>
                <div className=" py-1 w-full h-fit flex">
                    <form onSubmit={handleSubmit} className='px-5 w-full flex flex-col justify-between'>
                        {/* code */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={code} 
                                onChange={(e) => setCode(e.target.value)} 
                                id="code" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {codeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="code" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Code</label>
                        </div>
                        {/* phone */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                value={phone} 
                                onChange={(e) => setPhone(e.target.value)} 
                                type="number" 
                                name="phone" 
                                minLength={5}
                                id="phone" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="phone" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Phone</label>
                        </div>
                        {/* status */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {mainOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Add</button>
                            <button 
                                type="button" 
                                onClick={handleReset}
                                className="mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center text-white bg-red-400 hover:bg-red-500 cursor-pointer"
                            >Reset</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

CardOfficePhones.propTypes = {
    slug: PropTypes.string,
    setModalData: PropTypes.func,
    setShowModalPhone: PropTypes.func,
    fetchAgain: PropTypes.bool,
    setFetchAgain: PropTypes.func,
}

export default CardOfficePhones