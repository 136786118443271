import React, { useEffect, useRef, useState } from 'react'
import { IoIosCloseCircle } from "react-icons/io";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { addPostGalleries, deletePostGallery, getPostGalleries } from '../../utilities/sendRequest';

const CardGalleries = ({ slug, setModalData, setShowModalGallery, fetchAgain, setFetchAgain }) => {
    const [isReady, setIsReady] = useState(false)
    const [fetchMore, setFetchMore] = useState(false)
    const [title, setTitle] = useState("")
    const photoRef = useRef(null); 
    const [photo, setPhoto] = useState(null)
    const [datas, setDatas] = useState([])

    useEffect(() => {
        fetchGalleries();
        setFetchAgain(false)
        setFetchMore(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore, fetchAgain])

    useEffect(() => {
        const isReady = (title !== "" && photo !== null);
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, photo])

    const fetchGalleries = async () => {
        getPostGalleries(slug).then((res) => {
            setDatas(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setIsReady(false)
        const reqData = new FormData();
        reqData.append("title", title);
        
        if (photo) {
            reqData.append("file", photo);
        }

        addPostGalleries(slug, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            handleResetForm()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }
    
    const handleResetForm = () => {
        setTitle("")
        handleResetPreview()
    }

    const handleResetPreview = (id) => {
        setPhoto(null)
        photoRef.current.value = null;
    }

    const columns = [
        { 
            field: 'title', 
            headerName: 'Title',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.title}
                    </div>
                )
            } 
        },
        { 
            field: 'path', 
            headerName: 'Photo',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center'>
                        <div className='w-10 h-10 bg-grayPrimary'>
                            <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_IMAGES}/${params.row.path}`} alt={params.row.title} className='w-full h-full object-cover' />
                        </div>
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <div onClick={() => handleModal(params.row)}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </div>
                        <DeleteIcon fontSize='medium' className="text-red-400 hover:text-red-500 cursor-pointer" onClick={() => handleDelete(params.row.id)}/>
                        
                    </div>
                )
            }
        },
    ]

    const handleDelete = async (paramsID) => {
        deletePostGallery(paramsID).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleModal = (data) => {
        setModalData(data)
        setShowModalGallery(true)
    }

    return (
        <div className='my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10'>
            {/* list data */}
            <div className='w-full h-full lg:w-[60%] flex flex-col gap-3 items-center'>
                <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={5}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>

            {/* form create */}
            <div className="px-2 py-4 w-full h-fit lg:w-[40%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                <h1 className='font-semibold text-base font-ssp px-3'>Upload New Photo Galleries</h1>
                <hr className='px-3'/>
                <div className=" py-1 w-full h-fit flex">
                    <form onSubmit={handleSubmit} className='px-5 w-full flex flex-col justify-between'>
                        {/* title */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>  
                        {/* photo */}
                        <div className="mb-3 mt-3 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_input2"
                            >Upload Photo</label>
                            <input 
                                type="file" 
                                name="photo"
                                ref={photoRef}
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept='.png, .jpg, .jpeg'
                                className="block w-full text-sm text-slate-500
                                    file:mr-4 file:py-2 file:px-4
                                    file:rounded-full file:border-0
                                    file:text-sm file:font-semibold
                                    file:bg-gray-50 file:text-gray-500
                                    hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_input2_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {photo && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(photo)} alt="Preview" className="w-1/4" />
                                <IoIosCloseCircle  fontSize='25px'
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer'
                                    onClick={() => handleResetPreview()} />
                            </div>
                        }         
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Submit</button>
                            <button 
                                type="button" 
                                onClick={handleResetForm}
                                className="mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center text-white bg-red-400 hover:bg-red-500 cursor-pointer"
                            >Reset</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

CardGalleries.propTypes = {
    slug: PropTypes.string,
    setModalData: PropTypes.func,
    setShowModalGallery: PropTypes.func,
    fetchAgain: PropTypes.bool,
    setFetchAgain: PropTypes.func,
}

export default CardGalleries