import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { IoIosCloseCircle } from 'react-icons/io';
import { FaCloudDownloadAlt } from "react-icons/fa";
import QRCode from "react-qr-code";
import { toPng } from 'html-to-image';
import { createSlug } from '../../utilities/helper';
import { AuthContext } from '../../context/authContext';
import { getPost, updatePost } from '../../utilities/sendRequest';
import ModalEventDetail from '../../components/Posts/modalEvent';
import CardGalleries from '../../components/Posts/cardGalleries';
import CardEvents from '../../components/Posts/cardEvents';
import ModalGalleryDetail from '../../components/Posts/modalGallery';
import { postTypeOptions, statusOptions } from '../../utilities/selectOptions';

const DetailPost = () => {
    const navigate = useNavigate()
    const [showModalEvent, setShowModalEvent] = useState(false)
    const [showModalGallery, setShowModalGallery] = useState(false)
    const [fetchAgain, setFetchAgain] = useState(false)
    const elementRef = useRef(null)
    const thumbnailRef = useRef(null)
    const params = useParams()
    const [title, setTitle] = useState("")
    const [thumbnail, setThumbnail] = useState("")
    const [type, setType] = useState("")
    const [scheduleDate, setScheduleDate] = useState("")
    const [status, setStatus] = useState("")
    const [isReady, setIsReady] = useState(false)
    const [data, setData] = useState([])
    const [modalData, setModalData] = useState([])
    const { user } = useContext(AuthContext)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    
    const [convertedContent, setConvertedContent] = useState(null);
    
    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    useEffect(() => {
        const isReady = (title !== "" && status !== "" && type !== "" && scheduleDate !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, status, type, scheduleDate])
    
    useEffect(() => {
        fetchPost()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchPost = async () => {
        getPost(params.slug).then((res) => {
            setData(res.payload)
            setTitle(res.payload.title)
            setType(res.payload.type)
            setStatus(res.payload.is_active)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.payload.description))))
            setScheduleDate(new Date(new Date(res.payload.published_at).getTime() + (7 * 60 * 60 * 1000)).toISOString().slice(0, -1))
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetPreview = () => {
        setThumbnail(null)
        thumbnailRef.current.value = null;
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
    
        setIsReady(false)
        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("slug", createSlug(title));
        reqData.append("description", convertedContent);
        reqData.append("published_at", scheduleDate);
        reqData.append("is_active", status);
        reqData.append("type", type);
        reqData.append("email", user.email);

        if (thumbnail) {
            reqData.append("thumbnail", thumbnail);
        }

        updatePost(params.slug, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchPost()
            handleResetPreview()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const htmlToImageConvert = () => {
        toPng(elementRef.current, { cacheBust: false })
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "my-image-name.png";
            link.href = dataUrl;
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      };

    return (
        <>
            {showModalEvent && 
                <ModalEventDetail setShowModalEvent={setShowModalEvent} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            {showModalGallery &&
                <ModalGalleryDetail setShowModalGallery={setShowModalGallery} modalData={modalData} setFetchAgain={setFetchAgain} />
            }
            
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col ${(showModalEvent || showModalGallery) && 'fixed min-w-[calc(100vw-16.666667%)]'} `}>
                {/* header  */}
                <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                    <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Post</h1>
                </div>

                {/* content */}
                <div className="my-5 mx-7 md:mx-5 flex flex-col justify-evenly gap-7 md:gap-10">
                    <div className="w-full h-full flex flex-col lg:flex-row justify-around gap-5 items-center">
                        {data.thumbnail && 
                            <div className='px-3 py-4 w-full lg:max-w-[30%] h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                                <h3 className='text-lg font-ssp font-semibold text-center'>Thumbnail</h3>
                                <div className='w-full h-[300px]'>
                                    <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_IMAGES}/${data.thumbnail}`} alt={data.title} className='w-full h-full object-cover' />
                                </div>
                            </div>
                        }
                        {data.type === "event" && 
                            <div className='px-3 py-4 w-full lg:max-w-[30%] h-full bg-white drop-shadow-lg rounded-md flex flex-col gap-2'>
                                <div className='relative w-full flex justify-center'>
                                    <h3 className='text-lg font-ssp font-semibold text-center'>QR Absesnsi</h3>
                                    <FaCloudDownloadAlt className='h-7 w-7 absolute right-1 cursor-pointer' onClick={htmlToImageConvert} />
                                </div>
                                <div className='w-full h-[300px]'>
                                    <QRCode
                                        ref={elementRef}
                                        size={256}
                                        className='w-full h-full object-contain p-1'
                                        value={`${process.env.REACT_APP_EMPLOYEE_URL_EVENTS}/${data.slug}`}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    {/* form update */}
                    <div className="px-2 py-4 w-full bg-white drop-shadow-lg rounded-md flex">
                        <form onSubmit={handleUpdate} className='px-5 w-full flex flex-wrap justify-between'>
                            {/* id enterprise */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={title} 
                                    onChange={(e) => setTitle(e.target.value)} 
                                    type="text" 
                                    name="title" 
                                    id="title" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="title" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Title</label>
                            </div>
                            {/* type */}
                            <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                                <select 
                                    required 
                                    value={type} 
                                    onChange={(e) => setType(e.target.value)} 
                                    id="type" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer"
                                >
                                    {postTypeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="type" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                                peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Type</label>
                            </div>
                            {/* publish date */}
                            <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                                <input 
                                    minLength={3} 
                                    value={scheduleDate} 
                                    onChange={(e) => setScheduleDate(e.target.value)} 
                                    type="datetime-local" 
                                    name="scheduleDate" 
                                    id="scheduleDate" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer" 
                                    placeholder=" " 
                                    required 
                                />
                                <label 
                                    htmlFor="scheduleDate" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Publish Date</label>
                            </div>
                            {/* status */}
                            <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                                <select 
                                    required 
                                    value={status} 
                                    onChange={(e) => setStatus(e.target.value)} 
                                    id="status" 
                                    className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                                border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                focus:border-blue-600 peer"
                                >
                                    {statusOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                                <label 
                                    htmlFor="status" 
                                    className="absolute top-3 -z-10 text-sm text-gray-500 
                                                duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                                peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Status</label>
                            </div>
                            {/* description */}
                            <div className="mt-3 mb-6 w-full relative z-0 group">
                                <label 
                                    htmlFor="description" 
                                    className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >Description</label>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                                    toolbarClassName="sticky"
                                    editorClassName="px-2 overflow-y-scroll"
                                />
                            </div>
                            {/* thumbnail */}
                            <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                                <label 
                                    className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                    htmlFor="file_thumbnail"
                                >Upload New Thumbnail</label>
                                <input 
                                    type="file" 
                                    name="thumbnail"
                                    ref={thumbnailRef}
                                    onChange={(e) => setThumbnail(e.target.files[0])}
                                    accept='.png,.jpg,.jpeg'
                                    className="block w-full text-sm text-slate-500
                                                file:mr-4 file:py-2 file:px-4
                                                file:rounded-full file:border-0
                                                file:text-sm file:font-semibold
                                                file:bg-gray-50 file:text-gray-500
                                                hover:file:bg-gray-100"
                                />
                                <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_thumbnail_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                            
                            </div>
                            {thumbnail && 
                                <div className="mt-3 w-full relative flex flex-row group">
                                    <img src={URL.createObjectURL(thumbnail)} alt="Preview" className="max-w-[300px] max-h-[200px] object-cover" />
                                    <IoIosCloseCircle 
                                        fontSize='25px' 
                                        className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                        onClick={() => handleResetPreview()} />
                                </div>
                            }
                            {/* button */}
                            <div className="mt-2 mb-6 w-full relative z-0 flex flex-wrap justify-center gap-0 md:gap-5">
                                <button 
                                    type="submit" 
                                    disabled={!isReady}
                                    className={`px-5 py-2.5 mt-2 md:mt-5 w-full h-fit sm:w-auto ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg 
                                    font-medium text-sm text-center`}
                                >Update</button>
                                <button 
                                type='button'
                                    onClick={() => navigate(-1)} 
                                    className="px-5 py-2.5 mt-2 md:mt-5 w-full h-fit sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                                font-medium text-sm text-center text-white cursor-pointer"
                                >Back</button>
                            </div>
                        </form>
                    </div>

                    {/* events */}
                    {data.type === 'event'
                        ? <CardEvents slug={params.slug} setModalData={setModalData} setShowModalEvent={setShowModalEvent} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                        : ""
                    }
                    
                    {/* gallery photos */}
                    <CardGalleries slug={params.slug} setModalData={setModalData} setShowModalGallery={setShowModalGallery} fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
                </div>
            </div>
        </>
    )
}

export default DetailPost