import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { IoIosCloseCircle } from 'react-icons/io';
import { getReward, updateReward } from '../../utilities/sendRequest';
import { AuthContext } from '../../context/authContext';
import { statusOptions } from '../../utilities/selectOptions';

const DetailReward = () => {
    const params = useParams()
    const { user } = useContext(AuthContext)
    const photoRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const [photo, setPhoto] = useState("")
    const [data, setData] = useState([])
    const [title, setTitle] = useState("")
    const [point, setPoint] = useState("")
    const [publish, setPublish] = useState("")
    const [expired, setExpired] = useState("")
    const [status, setStatus] = useState("")
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [convertedContent, setConvertedContent] = useState(null);
    
    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    useEffect(() => {
        const isReady = (title !== "" && point !== "" && publish !== "" && expired !== "" && status !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, point, publish, expired, status])
    
    useEffect(() => {
        fetchReward()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchReward = async () => {
        getReward(params.id).then((res) => {
            setData(res.payload);
            setTitle(res.payload.title)
            setPoint(res.payload.point)
            setPublish(new Date(new Date(res.payload.published_at).getTime() + (7 * 60 * 60 * 1000)).toISOString().slice(0, -1))
            setExpired(new Date(new Date(res.payload.expired_at).getTime() + (7 * 60 * 60 * 1000)).toISOString().slice(0, -1))
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(res.payload.description))))
            setStatus(res.payload.is_active)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const onlyNumbers = /^\d*$/;
        let pointCheck = 0;
        if (onlyNumbers.test(point) || point === '') {
            pointCheck = point;
        }
        
        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("point", pointCheck);
        reqData.append("published_at", publish);
        reqData.append("expired_at", expired);
        reqData.append("description", convertedContent);
        reqData.append("email", user.email);
        reqData.append("is_active", status);

        if(photo) {
            reqData.append("photo", photo);
        }

        updateReward(params.id, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchReward()
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const handleResetPreview = () => {
        setPhoto(null)
        photoRef.current.value = null;
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Reward</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col gap-10">
                {/* photo */}
                <div className="px-7 py-5 w-full md:w-[30%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                    <div className='mt-3 w-full drop-shadow-lg flex justify-center'>
                        <img src={`${process.env.REACT_APP_PUBLIC_FOLDER_IMAGES}/${data.photo}`} alt={data.photo} className='w-full h-full object-cover'/>
                    </div>
                </div>

                {/* form update */}
                <div className="px-2 py-4 w-full flex bg-white drop-shadow-lg rounded-md">
                    <form onSubmit={handleUpdate} className='px-5 py-3 w-full flex flex-wrap justify-between'>
                        {/* title */}
                        <div className="relative z-0 mb-6 w-full md:w-[60%] group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Firstname</label>
                        </div>
                        {/* point */}
                        <div className="relative z-0 mb-6 w-full md:w-[35%] group">
                            <input 
                                value={point} 
                                onChange={(e) => setPoint(e.target.value)} 
                                type="text" 
                                name="point" 
                                id="point" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="point" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Point</label>
                        </div>
                        {/* publish */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={publish} 
                                onChange={(e) => setPublish(e.target.value)} 
                                type="datetime-local" 
                                name="publish" 
                                id="publish" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="publish" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Publish At</label>
                        </div>
                        {/* expired */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={expired} 
                                onChange={(e) => setExpired(e.target.value)} 
                                type="datetime-local" 
                                name="expired" 
                                id="expired" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="expired" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Expired</label>
                        </div>
                        {/* status */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <select required value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                {statusOptions.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* description */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <label 
                                htmlFor="description" 
                                className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Description</label>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                                toolbarClassName="sticky"
                                editorClassName="px-2 overflow-y-scroll"
                            />
                        </div>
                        {/* photo */}
                        <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_thumbnail"
                            >Upload New Photo</label>
                            <input 
                                type="file" 
                                name="photo"
                                ref={photoRef}
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept='.png,.jpg,.jpeg'
                                className="block w-full text-sm text-slate-500
                                            file:mr-4 file:py-2 file:px-4
                                            file:rounded-full file:border-0
                                            file:text-sm file:font-semibold
                                            file:bg-gray-50 file:text-gray-500
                                            hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_photo_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                        
                        </div>
                        {photo && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(photo)} alt="Preview" className="max-w-[300px] max-h-[200px] object-cover" />
                                <IoIosCloseCircle 
                                    fontSize='25px' 
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                    onClick={() => handleResetPreview()} />
                            </div>
                        }
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Update</button>
                            <Link 
                                to={'/rewards'} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Back</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DetailReward