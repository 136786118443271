import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { createPostEvent, getPostEvents, togglePostEvent } from '../../utilities/sendRequest';
import { dateTimeIndo } from '../../utilities/helper';
import { statusOptions } from '../../utilities/selectOptions';

const CardEvents = ({ slug, setModalData, setShowModalEvent, fetchAgain, setFetchAgain }) => {
    const [isReady, setIsReady] = useState(false)
    const [fetchMore, setFetchMore] = useState(false)
    const [point, setPoint] = useState("0")
    const [url, setUrl] = useState("")
    const [publish, setPublish] = useState("")
    const [statusEvent, setStatusEvent] = useState("")
    const [titleEvent, setTitleEvent] = useState("HYBRID")
    const [datas, setDatas] = useState([])

    useEffect(() => {
        fetchEvents();
        setFetchAgain(false)
        setFetchMore(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore, fetchAgain])

    useEffect(() => {
        const isReady = (titleEvent !== "" && statusEvent !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleEvent, statusEvent])

    const fetchEvents = async () => {
        getPostEvents(slug).then((res) => {
            setDatas(res.payload)
           
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const columns = [
        { 
            field: 'title', 
            headerName: 'Event',
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.title}
                    </div>
                )
            } 
        },
        {
            field: 'point',
            headerName: 'Point',
            width: 100,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.point}
                    </div>
                )
            } 
        },
        { 
            field: 'published_at', 
            headerName: 'Expired', 
            width: 200, 
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {dateTimeIndo(params.row.published_at)}
                    </div>
                )
            } 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <div onClick={() => handleModal(params.row)}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </div>
                        {params.row.is_active
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.id)}/>
                        }
                    </div>
                )
            }
        },
    ]

    const handleToggle = (paramsID) => {
        togglePostEvent(paramsID).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            fetchEvents()
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        setIsReady(false)
        const reqDataEvent = new FormData();
        reqDataEvent.append("title", titleEvent);
        reqDataEvent.append("url", url);
        reqDataEvent.append("point", point);
        reqDataEvent.append("published_at", publish);
        reqDataEvent.append("is_active", statusEvent);

        createPostEvent(slug, reqDataEvent).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            handleResetEvent();
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const handleResetEvent = () => {
        setTitleEvent(0)
        setUrl("")
        setPoint(0)
        setPublish("")
        setStatusEvent("")
    }

    const handleModal = (data) => {
        setModalData(data)
        setShowModalEvent(true)
    }

    return (
        <div className='my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10'>
            {/* list data */}
            <div className='w-full h-full lg:w-[60%] flex flex-col gap-3 items-center'>
                <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={5}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>

            {/* form create */}
            <div className="px-2 py-4 w-full h-fit lg:w-[40%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                <h1 className='font-semibold text-base font-ssp px-3'>Create Event</h1>
                <hr className='px-3'/>
                <div className=" py-1 w-full h-fit flex">
                    <form onSubmit={handleSubmit} className='px-5 w-full flex flex-col justify-between'>
                        {/* title event */}
                        <div className="hidden mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                value={titleEvent} 
                                type="hidden" 
                                name="event" 
                                id="event" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="event" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Event</label>
                        </div>
                        {/* point */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                value={point} 
                                onChange={(e) => setPoint(e.target.value)} 
                                type="number" 
                                name="point" 
                                id="point" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="point" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Point</label>
                        </div>
                        {/* url */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={5} 
                                value={url} 
                                onChange={(e) => setUrl(e.target.value)} 
                                type="text" 
                                name="url" 
                                id="url" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                                 
                            />
                            <label 
                                htmlFor="url" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >URL Zoom</label>
                        </div>
                        {/* publishDate */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={5} 
                                value={publish} 
                                onChange={(e) => setPublish(e.target.value)} 
                                type="datetime-local" 
                                name="publishDate" 
                                id="publishDate" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="url" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Expired At</label>
                        </div>
                        {/* status */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={statusEvent} 
                                onChange={(e) => setStatusEvent(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {statusOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Create</button>
                            <button 
                                type="button" 
                                onClick={handleResetEvent}
                                className="mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center text-white bg-red-400 hover:bg-red-500 cursor-pointer"
                            >Reset</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

CardEvents.propTypes = {
    slug: PropTypes.string,
    setModalData: PropTypes.func,
    setShowModalEvent: PropTypes.func,
    fetchAgain: PropTypes.bool,
    setFetchAgain: PropTypes.func,
}

export default CardEvents