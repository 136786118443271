import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { AuthContext } from '../../context/authContext';
import { addOffices, getOffices, logoutUser, toggleOffices } from '../../utilities/sendRequest';
import { createSlug } from '../../utilities/helper';
import { statusOptions } from '../../utilities/selectOptions';
import { Link } from 'react-router-dom';

const Offices = () => {
    const { dispatch } = useContext(AuthContext)
    const [datas, setDatas] = useState([])
    const [fetchMore, setFetchMore] = useState(true)
    const [isReady, setIsReady] = useState(false)

    const [name, setName] = useState("")
    const [status, setStatus] = useState("")
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    
    const [convertedContent, setConvertedContent] = useState(null);

    useEffect(() => {
        const isReady = (name, status);
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, status])

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);

    }, [editorState]);

    useEffect(() => {
        fetchOffices()
        setFetchMore(false)

        // eslint-disable-next-line
    }, [fetchMore])

    const fetchOffices = async () => {
        getOffices().then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt)
                })
            )
        }).catch((error) => {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const resetForm = () => {
        setName("")
        setEditorState(() => EditorState.createEmpty())
        setStatus("")
    }

    const handleToggle = (paramsSlug) => {
        toggleOffices(paramsSlug).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const handleCreate = async (e) => {
        e.preventDefault()

        setIsReady(false);
        const slug = createSlug(name)

        const reqData = {
            name,
            slug,
            description: convertedContent,
            is_active: status
        }

        addOffices(reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true);
            resetForm();
        })
    }

    const columns = [
        { 
            field: 'name', 
            headerName: 'Name',
            width: 350, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.name}
                    </div>
                )
            } 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.is_active
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">active</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">inactive</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <Link to={`/offices/${params.row.slug}`}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </Link>
                        {params.row.is_active
                            ? <ToggleOnIcon className="text-green-400 hover:text-green-500 cursor-pointer" onClick={() => handleToggle(params.row.slug)}/>
                            : <ToggleOffIcon className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={() => handleToggle(params.row.slug)}/>
                        }
                    </div>
                )
            }
        },
    ]

    return (
            <div className={`min-h-[calc(100vh-4rem)] flex flex-col`}>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Offices</h1>
                </div>
                {/* content  */}
                <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10">
                    {/* list data */}
                    <div className='w-full h-full lg:w-[60%] flex flex-col gap-3 items-center'>
                        <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                            <DataGrid
                                autoHeight {...datas}
                                rows={datas}
                                columns={columns}
                                pageSize={5}
                                getRowId={(row) => row.slug}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectionOnClick
                                components={{ Toolbar: GridToolbar, }}
                            />
                        </div>
                    </div>

                    {/* form create */}
                    <div className="px-2 py-4 w-full h-fit lg:w-[40%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                        <h1 className='font-semibold text-base font-ssp px-3'>Create New Office</h1>
                        <hr className='px-3'/>
                        <div className=" py-1 w-full h-fit flex">
                            <form onSubmit={handleCreate} className='px-5 w-full flex flex-col justify-between'>
                                {/* name */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <input 
                                        minLength={5} 
                                        value={name} 
                                        onChange={(e) => setName(e.target.value)} 
                                        type="text" 
                                        name="name" 
                                        id="name" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer" 
                                        placeholder=" " 
                                        required 
                                    />
                                    <label 
                                        htmlFor="title" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 
                                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                    peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                    peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Name</label>
                                </div>
                                {/* description */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <label 
                                        htmlFor="description" 
                                        className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                                    scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                                    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Description</label>
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                        wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                                        toolbarClassName="sticky"
                                        editorClassName="px-2 overflow-y-scroll"
                                    />
                                </div>
                                {/* status */}
                                <div className="mt-3 mb-6 w-full relative z-0 group">
                                    <select 
                                        required 
                                        value={status} 
                                        onChange={(e) => setStatus(e.target.value)} 
                                        id="status" 
                                        className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                                    border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                                    focus:border-blue-600 peer"
                                    >
                                        {statusOptions.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                    <label 
                                        htmlFor="status" 
                                        className="absolute top-3 -z-10 text-sm text-gray-500 
                                                    duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                                    peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                                    peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >Status</label>
                                </div>
                                {/* button */}
                                <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                                    <button 
                                        type="submit" 
                                        disabled={!isReady}
                                        className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                                    >Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Offices