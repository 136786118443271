import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { IoIosCloseCircle } from 'react-icons/io';
import { createSlug } from '../../utilities/helper';
import { AuthContext } from '../../context/authContext';
import { addPost } from '../../utilities/sendRequest';
import { postTypeOptions, statusOptions } from '../../utilities/selectOptions';

const CreatePost = () => {
    const navigate = useNavigate()
    const thumbnailRef = useRef(null)
    const [title, setTitle] = useState("")
    const [thumbnail, setThumbnail] = useState("")
    const [type, setType] = useState("")
    const [scheduleDate, setScheduleDate] = useState("")
    const [status, setStatus] = useState("")
    const [isReady, setIsReady] = useState(false)
    const { user } = useContext(AuthContext)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    
    const [convertedContent, setConvertedContent] = useState(null);
    
    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    useEffect(() => {
        const isReady = (title !== "" && type !== "" && scheduleDate !== "" && status !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, type, scheduleDate, status])
    
    const handleResetPreview = () => {
        setThumbnail(null)
        thumbnailRef.current.value = null;
    }

    const handleCreate = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = new FormData();
        reqData.append("title", title);
        reqData.append("slug", createSlug(title));
        reqData.append("description", convertedContent);
        reqData.append("published_at", scheduleDate);
        reqData.append("is_active", status);
        reqData.append("type", type);
        reqData.append("email", user.email);

        if(thumbnail) {
            reqData.append("file", thumbnail);
        }

        addPost(reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            handleResetPreview()
            navigate("/posts")
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header  */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Create Post</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col justify-evenly gap-7 md:gap-10">
                {/* form create */}
                <div className="px-2 py-4 w-full bg-white drop-shadow-lg rounded-md flex">
                    <form onSubmit={handleCreate} className='px-5 w-full flex flex-wrap justify-between'>
                        {/* id enterprise */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                value={title} 
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                name="title" 
                                id="title" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="title" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Title</label>
                        </div>
                        {/* type */}
                        <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                            <select 
                                required 
                                value={type} 
                                onChange={(e) => setType(e.target.value)} 
                                id="type" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {postTypeOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="type" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Type</label>
                        </div>
                        {/* publish date */}
                        <div className="mt-3 mb-6 w-full md:w-[30%] relative z-0 group">
                            <input 
                                minLength={3} 
                                value={scheduleDate} 
                                onChange={(e) => setScheduleDate(e.target.value)} 
                                type="datetime-local" 
                                name="scheduleDate" 
                                id="scheduleDate" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="scheduleDate" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Publish At</label>
                        </div>
                        {/* status */}
                        <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                            <select 
                                required 
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                {statusOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium 
                                            peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* description */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <label 
                                htmlFor="description" 
                                className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                            scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                            peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Description</label>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                                toolbarClassName="sticky"
                                editorClassName="px-2 overflow-y-scroll"
                            />
                        </div>
                        {/* thumbnail */}
                        <div className="mb-6 mt-3 w-full md:w-[30%] relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_thumbnail"
                            >Upload Thumbnail</label>
                            <input 
                                
                                type="file" 
                                name="thumbnail"
                                ref={thumbnailRef}
                                onChange={(e) => setThumbnail(e.target.files[0])}
                                accept='.png,.jpg,.jpeg'
                                className="block w-full text-sm text-slate-500
                                            file:mr-4 file:py-2 file:px-4
                                            file:rounded-full file:border-0
                                            file:text-sm file:font-semibold
                                            file:bg-gray-50 file:text-gray-500
                                            hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_thumbnail_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                        </div>
                        {thumbnail && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(thumbnail)} alt="Preview" className="max-w-[300px] max-h-[200px] object-cover" />
                                <IoIosCloseCircle 
                                    fontSize='25px' 
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                    onClick={() => handleResetPreview()} />
                            </div>
                        }
                        {/* button */}
                        <div className="mt-2 mb-6 w-full relative z-0 flex flex-wrap justify-center gap-0 md:gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`px-5 py-2.5 mt-2 md:mt-5 w-full h-fit sm:w-auto ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg 
                                font-medium text-sm text-center`}
                            >Update</button>
                            <button type="button"
                                onClick={() => navigate(-1)} 
                                className="px-5 py-2.5 mt-2 md:mt-5 w-full h-fit sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg 
                                            font-medium text-sm text-center text-white cursor-pointer"
                            >Back</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreatePost