import React from 'react'
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';

const CardPoints = ({ data, setModalData, setShowModalUpdatePoint, setShowModalDetailPoint }) => {
    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {params.row.type === 'revenue'
                            ? <span className="px-2 py-1 bg-green-400 rounded-full text-white uppercase">Penambahan</span>
                            : <span className="px-2 py-1 bg-red-400 rounded-full text-white uppercase">Pengurangan</span>
                        }
                    </div>
                )
            } 
        },
        {
            field: 'point',
            headerName: 'Point',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.point}
                    </div>
                )
            } 
        },
        { 
            field: 'name', 
            headerName: 'Admin',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Approved_Point_Log.firstname+' '+params.row.Approved_Point_Log.lastname}
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <div onClick={() => handleLogPointModal(params.row)}>
                            <VisibilityIcon fontSize='medium' className="text-slate-400 hover:text-slate-500 cursor-pointer"/>
                        </div>
                    </div>
                )
            }
        },
    ]

    const handleAddPointModal = (data) => {
        setModalData(data)
        setShowModalUpdatePoint(true)
    }

    const handleLogPointModal = (data) => {
        setShowModalDetailPoint(true)
        setModalData(data)
    }

    return (
        <div className='my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10'>
            {/* point */}
            <div className="px-7 py-5 w-full md:w-[30%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                <span className='text-center font-semibold text-2xl tracking-wider'>Point</span>
                <hr />
                <div className='mt-3 w-52 h-52 p-2 bg-slate-900 rounded-full drop-shadow-lg font-extrabold text-white text-5xl text-center self-center flex justify-center items-center' >
                    {data.Point ? data.Point.balance : "-"}
                </div>
                <button 
                    onClick={() => handleAddPointModal(data)}
                    disabled={!data.Point}
                    type='button'
                    className={`px-5 py-2.5 mt-5 w-full sm:w-auto ${data.Point ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg 
                                font-medium text-sm text-center cursor-pointer`}
                >Update Point</button>
            </div>

            {/* log */}
            {data?.Obtained_Point_Log
                ?   <div className='w-full h-full lg:w-[60%] flex flex-col gap-3 items-center'>
                        <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                            <DataGrid
                                autoHeight {...data?.Obtained_Point_Log}
                                rows={data?.Obtained_Point_Log}
                                columns={columns}
                                pageSize={5}
                                getRowId={(row) => row.id}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectionOnClick
                                components={{ Toolbar: GridToolbar, }}
                            />
                        </div>
                    </div>
                :   ""
            }
        </div>
    )
}

CardPoints.propTypes = {
    data: PropTypes.object.isRequired,
    setModalData: PropTypes.func,
    setShowModalUpdatePoint: PropTypes.func,
    setShowModalDetailPoint: PropTypes.func
}

export default CardPoints