export const mainOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "OPSIONAL",
        value: false,
    },
    {
        label: "UTAMA",
        value: true,
    },
]

export const codeOptions = [
    {
        label: "Choose a Code",
        value: "",
    },
    {
        label: "Indonesia (+62)",
        value: 62,
    },
    {
        label: "Australia (+61)",
        value: 61,
    },
    {
        label: "Malaysia (+60)",
        value: 60,
    },
    {
        label: "Singapura (+65)",
        value: 65,
    },
    {
        label: "Hongkong (+852)",
        value: 852,
    },
]

export const typeOptions = [
    {
        label: "Choose a Type",
        value: ""
    }, 
    {
        label: "PENAMBAHAN",
        value: "revenue"
    },
    {
        label: "PENGURANGAN",
        value: "expense"
    },
]

export const statusOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "ACTIVE",
        value: true,
    },
    {
        label: "INACTIVE",
        value: false,
    },
]

export const familyOptions = [
    {
        label: "Choose a Status",
        value: "",
    },
    {
        label: "AYAH",
        value: "AYAH",
    },
    {
        label: "IBU",
        value: "IBU",
    },
    {
        label: "SUAMI",
        value: "SUAMI",
    },
    {
        label: "ISTRI",
        value: "ISTRI",
    },
    {
        label: "ANAK",
        value: "ANAK",
    },
    {
        label: "SAUDARA",
        value: "SAUDARA",
    },
]

export const postTypeOptions = [
    {
        label: "Choose a Type",
        value: "",
    },
    {
        label: "Event",
        value: "event",
    },
    {
        label: "Article",
        value: "article",
    },
]