import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { RiCloseFill } from "react-icons/ri";
import { updateEmployeePhone } from '../../utilities/sendRequest';
import { PopupContext } from '../../context/popupContext';
import { codeOptions, mainOptions } from '../../utilities/selectOptions';

const ModalPhoneDetail = ({ setShowModalPhone, modalData, setFetchAgain }) => {
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    const [isReady, setIsReady] = useState(false)

    const [phone, setPhone] = useState(modalData.Phone.phone_number)
    const [status, setStatus] = useState(modalData.Phone.is_main)
    const [code, setCode] = useState(modalData.Phone.code)
    
    useEffect(() => {
        const isReady = (phone !== "" && status !== "" && code !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone, status, code])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalPhone(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = {
            code,
            phone_number: phone,
            is_main: status
        }

        updateEmployeePhone(modalData.id, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });  
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
            setFetchAgain(true)
            setShowModalPhone(false)
        })
        popupDispatch({ type: "POPUP_END" });
    }

    const handleModal = () => {
        setShowModalPhone(null)
        popupDispatch({ type: "POPUP_END" });
    }

    return (
        <div className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className='font-bold font-ssp text-xl md:text-2xl'>Detail Phone Employee</h3>
                    <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                </div>
                <form onSubmit={handleSubmit} className='mt-5 px-4 w-full flex flex-col'>
                    {/* code */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={code} 
                            onChange={(e) => setCode(e.target.value)} 
                            id="code" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            {codeOptions.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <label 
                                htmlFor="code" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Code</label>
                    </div>
                    {/* phone */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <input 
                            value={phone} 
                            onChange={(e) => setPhone(e.target.value)} 
                            type="number" 
                            name="phone" 
                            minLength={5}
                            id="phone" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer" 
                            placeholder=" " 
                            required 
                        />
                        <label 
                            htmlFor="phone" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Phone</label>
                    </div>
                    {/* status */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <select 
                            required 
                            value={status} 
                            onChange={(e) => setStatus(e.target.value)} 
                            id="status" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                        border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                        focus:border-blue-600 peer"
                        >
                            {mainOptions.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <label 
                            htmlFor="status" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                        peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                        peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Status</label>
                    </div>
                    {/* Button */}
                    <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                        <button type="submit" 
                            disabled={!isReady}
                            className={`mt-5 px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg text-sm
                                        text-center font-medium`}
                        >Save</button>
                        <button onClick={handleModal} 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                        text-center font-medium cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalPhoneDetail.propTypes = {
    modalData: PropTypes.object,
    setShowModalPhone: PropTypes.func,
    setFetchAgain: PropTypes.func,
}

export default ModalPhoneDetail