import React from 'react';

const Dashboard = () => {
    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                hai
            </div>
        </div>
    )
}

export default Dashboard