import React, { useContext, useEffect, useRef, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import PropTypes from 'prop-types';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { RiCloseFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import { PopupContext } from "../../context/popupContext";
import { updateEchelon } from '../../utilities/sendRequest';

const ModalEchelonDetail = ({ echelon, setShowModalDetail, setFetchMore }) => {
    const clickRef = useRef(null)
    const [isReady, setIsReady] = useState(false)
    const { popupDispatch } = useContext(PopupContext)
    const [title, setTitle] = useState(echelon.title)
    const [code, setCode] = useState(echelon.code)
    const [editorState, setEditorState] = useState(
        () => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(echelon.description)))
    );
    
    const [convertedContent, setConvertedContent] = useState(null);
    
    useEffect(() => {
        const isReady = (title!== "" && code !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, code])

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
        
    }, [editorState]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModalDetail(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleSubmit = async(e) => {
        e.preventDefault()

        setIsReady(false);
        const reqData = {
            title,
            code,
            description: convertedContent
        }

        updateEchelon(echelon.code, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            setShowModalDetail(false)
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true);
        })

        popupDispatch({ type: "POPUP_END" });
    }
    
    const handleModal = () => {
        setShowModalDetail(null)
        popupDispatch({ type: "POPUP_END" });
    }

    return (
        <div  className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className="font-bold font-ssp text-xl md:text-2xl">Detail Echelon</h3>
                    <RiCloseFill onClick={handleModal} className="w-8 h-8 cursor-pointer"/>
                </div>
                <form onSubmit={handleSubmit} className="mt-5 px-5 w-full flex flex-col">
                    {/* title */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            value={title} 
                            onChange={(e) => setTitle(e.target.value)} 
                            type="text" 
                            name="title" 
                            id="title" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required 
                        />
                        <label 
                            htmlFor="title" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Title</label>
                    </div>
                    {/* code */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input  
                            value={code} 
                            onChange={(e) => setCode(e.target.value)} 
                            type="text" 
                            name="code" 
                            id="code" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required 
                        />
                        <label 
                            htmlFor="code" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Code</label>
                    </div>
                    {/* description */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <label 
                            htmlFor="description" 
                            className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                        scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Description</label>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                            toolbarClassName="sticky"
                            editorClassName="px-2 overflow-y-scroll"
                        />
                    </div>
                    {/* button  */}
                    <div className="relative z-0 mb-6 mt-5 w-full flex justify-end gap-3">
                        <button 
                            type="submit" 
                            disabled={!isReady}
                            className={`mt-5 px-5 py-2.5 w-full sm:w-auto ${isReady ? 'text-white bg-blue-400 hover:bg-blue-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'} rounded-lg text-sm
                                        text-center font-medium`}
                        >Save</button>
                        <button type="button" 
                            onClick={handleModal} 
                            className="mt-5 px-5 py-2.5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg font-medium text-sm text-white text-center cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalEchelonDetail.propTypes = {
    data: PropTypes.object,
    setShowModal: PropTypes.func,
    setFetchMore: PropTypes.func,
}

export default ModalEchelonDetail