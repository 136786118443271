import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { AuthContext } from '../../context/authContext';
import { getLogRewards, logoutUser, updateLogReward } from '../../utilities/sendRequest';
import { dateTimeIndo } from '../../utilities/helper';

const LogRewards = () => {
    const { dispatch } = useContext(AuthContext)
    const [datas, setDatas] = useState([])
    const [fetchMore, setFetchMore] = useState(true)

    useEffect(() => {
        fetchLogs()
        setFetchMore(false)
        
        // eslint-disable-next-line
    }, [fetchMore])

    const fetchLogs = async () => {
        getLogRewards().then((res) => {
            setDatas(res.payload.sort((p1, p2) => {
                return new Date(p2.createdAt) - new Date(p1.createdAt)
            }))
        }).catch((error) => {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            if (error.response.status === 401) {
                setTimeout(() => {
                    logoutUser(dispatch)
                }, 3500)
            }
        })
    }

    const columns = [
        { 
            field: 'createdAt', 
            headerName: 'Time', 
            width: 150, 
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        {dateTimeIndo(params.row.createdAt)}
                    </div>
                )
            } 
        },
        { 
            field: 'Obtained_Reward_Log', 
            headerName: 'Employee',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Obtained_Reward_Log?.firstname+' '+params.row.Obtained_Reward_Log?.lastname+' - '+params.row.Obtained_Reward_Log?.nip}
                    </div>
                )
            } 
        },
        { 
            field: 'Reward.title', 
            headerName: 'Reward',
            width: 250, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Reward?.title}
                    </div>
                )
            } 
        },
        { 
            field: 'Reward.point', 
            headerName: 'Point',
            width: 100, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.Reward?.point}
                    </div>
                )
            } 
        },
        {
            field: 'type',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className="font-ssp">
                        <span className="px-2 py-1 bg-gray-400 rounded-full text-white uppercase">{params.row.status}</span>
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='flex justify-center gap-2'>
                        <button
                            onClick={() => handleAdmin(params.row, "success")}
                            className='w-full flex flex-row gap-3 items-center'>
                            <CheckCircleIcon fontSize='medium' className="text-green-400 hover:text-green-500 cursor-pointer"/>
                        </button>
                        <button
                            onClick={() => handleAdmin(params.row, "failed")}
                            className='w-full flex flex-row gap-3 items-center'>
                            <BlockIcon fontSize='medium' className="text-red-400 hover:text-red-500 cursor-pointer"/>
                        </button>
                    </div>
                )
            }
        },
    ]

    const handleAdmin = (data, paramsStatus) => {
        const reqData = {
            status: paramsStatus,
        }

        updateLogReward(data.id, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setFetchMore(true)
        });
    }

    return (
            <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
                {/* header  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex flex-col md:flex-row justify-between">
                    <h1 className='font-ssp font-bold text-2xl md:text-3xl'>Log Rewards</h1>
                </div>
                {/* content  */}
                <div className="my-5 mb-5 mx-7 md:mx-5 flex">
                    <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                        <DataGrid
                            autoHeight {...datas}
                            rows={datas}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.id}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar, }}
                        />
                    </div>
                </div>
            </div>
    )
}

export default LogRewards