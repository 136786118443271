import React, { useContext, useState } from 'react';
import { AuthContext } from "../../context/authContext";
import { changePassword, logoutUser } from '../../utilities/sendRequest';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const { user, dispatch } = useContext(AuthContext)
    const [firstname, setFirstname] = useState(user?.firstname)
    const [lastname, setLastname] = useState(user?.lastname)
    const [email, setEmail] = useState(user?.email)
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const navigate = useNavigate()

    const handleUpdate = async (e) => {
        e.preventDefault()

        if(newPassword !== confirmPassword) {
            return toast.error("New password not match", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }

        const reqData = {
            old_password: password,
            new_password: newPassword,
        }

        changePassword(user.nip, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });

            logoutUser(dispatch)
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })

        setPassword("")
        setNewPassword("")
        setConfirmPassword("")
    }
    console.log(user);

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Detail Profile</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row gap-10">
                {/* form update */}
                <div className="px-2 py-4 w-full lg:w-1/3 md:w-10/12 flex bg-white drop-shadow-lg rounded-md">
                    <form onSubmit={handleUpdate} className='px-5 w-full flex flex-col'>
                        {/* firstname */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                readOnly
                                value={firstname} 
                                onChange={(e) => setFirstname(e.target.value)} 
                                type="text" 
                                name="firstname" 
                                id="firstname" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="firstname" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Firstname</label>
                        </div>
                        {/* lastname */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                readOnly
                                value={lastname} 
                                onChange={(e) => setLastname(e.target.value)} 
                                type="text" 
                                name="lastname" 
                                id="lastname" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="lastname" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Lastname</label>
                        </div>
                        {/* email */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={3} 
                                value={email} 
                                readOnly
                                onChange={(e) => setEmail(e.target.value)} 
                                type="text" 
                                name="email" 
                                id="email" 
                                autoComplete='username'
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "
                                required 
                            />
                            <label 
                                htmlFor="email" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Email</label>
                        </div>
                        {/* password old */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={6} 
                                value={password} 
                                required 
                                onChange={(e) => setPassword(e.target.value)} 
                                type="password" 
                                name="current-password" 
                                id="current-password" 
                                autoComplete='current-password'
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="current-password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Password</label>
                        </div>
                        {/* password baru */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={6} 
                                value={newPassword} 
                                onChange={(e) => setNewPassword(e.target.value)} 
                                type="password" 
                                name="new-password" 
                                id="new-password" 
                                autoComplete='new-password'
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="new-password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >New Password</label>
                        </div>
                        {/* confirm password baru */}
                        <div className="mb-6 w-full relative z-0 group">
                            <input 
                                minLength={6} 
                                value={confirmPassword} 
                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                type="password" 
                                name="confirm-password"
                                id="confirm-password"
                                autoComplete='confirm-password'
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 
                                            focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="confirm-password" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                            peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Confirm New Password</label>
                        </div>
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-green-400 hover:bg-green-500 rounded-lg 
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Submit</button>
                            <button 
                                onClick={() => navigate(-1)} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Back</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Profile