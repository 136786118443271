import { format } from 'date-fns';
import { id } from 'date-fns/locale'; // Import Indonesian locale

// Function format rupiah
export const formatRupiah = (angka) => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    return formatter.format(angka);
}

// Fungsi untuk format tanggal dan waktu menjadi 20 Agustus 2022 11:11:00
export const dateTimeIndo = (formatDate) => {
  // Ubah format tanggal menjadi "24 Agustus 2023"
  const tanggalFormatted = format(new Date(formatDate), 'dd MMMM yyyy HH:mm:ss', { locale: id }); // Gunakan locale 'id' untuk bahasa Indonesia

  return tanggalFormatted;
}

// function check timestamp
export const checkTimeStamp = (startDate, startTime, endDate, endTime) => {

  const combinedDateTimeString = startDate + 'T' + startTime;
  const timestamp = new Date(combinedDateTimeString).getTime() / 1000; // Dibagi 1000 untuk mendapatkan timestamp dalam detik
  
  const combinedDateTimeString2 = endDate + 'T' + endTime;
  const timestamp2 = new Date(combinedDateTimeString2).getTime() / 1000; // Dibagi 1000 untuk mendapatkan timestamp dalam detik

  if (timestamp < timestamp2) {
      // console.log("start lebih awal");
      return true
  } else {
      // console.log("end lebih awal");
      return false
  }
}

export const createSlug = (str) => {
  return str
    .toLowerCase() // Ubah ke huruf kecil
    .trim() // Hapus spasi di awal dan akhir string
    .replace(/[^a-z0-9\s-]/g, '') // Hapus karakter khusus kecuali huruf, angka, spasi, dan dash (-)
    .replace(/\s+/g, '-') // Ganti spasi dengan dash (-)
    .replace(/-+/g, '-') // Ganti multiple dash (-) dengan satu dash (-)
    .substring(0, 50); // Batasi panjang slug menjadi 50 karakter
}

export const createTimeStamp = (inputDate, inputTime) => {
  // Pisahkan nilai tanggal dan waktu
  const [year, month, day] = inputDate.split('-'); // Pisahkan tahun, bulan, dan hari
  const [hour, minute] = inputTime.split(':'); // Pisahkan jam dan menit

  // Buat objek Date dari nilai tanggal dan waktu
  const dateTime = new Date(year, month - 1, day, hour, minute);

  // Ambil nilai timestamp
  const timestamp = dateTime.getTime(); // Nilai timestamp dalam milidetik
  
  return timestamp
}

export const timestampToDate = (timestamp) => {
    const date = new Date(timestamp);

    // Ambil tanggal, bulan, dan tahun
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Format tanggal sebagai input date
    const inputDate = `${year}-${month}-${day}`;

    // Kembalikan objek dengan input date dan input time
    return inputDate
}

export const timestampToTime = (timestamp) => {
    const date = new Date(timestamp);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format waktu sebagai input time
    const inputTime = `${hours}:${minutes}`;

    // Kembalikan objek dengan input date dan input time
    return  inputTime
}

export const dateDDMMYYY = (formatDate) => {
  // Memisahkan tahun, bulan, dan tanggal dengan metode split()
  const [tahun, bulan, tanggal] = formatDate.split('-');

  // Menggabungkan kembali nilai-nilai dalam format yang diinginkan
  const tanggalFormatted = `${tanggal}-${bulan}-${tahun}`;

  return tanggalFormatted; // Output: 01 01 2023
}