import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RiCloseFill } from "react-icons/ri";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { dateTimeIndo } from '../../utilities/helper';
import { PopupContext } from '../../context/popupContext';

const ModalPointDetail = ({ data, setShowModal }) => {
    const { popupDispatch } = useContext(PopupContext)
    const clickRef = useRef(null)
    
    const [editorState, setEditorState] = useState(
        data.description === null
        ? EditorState.createEmpty()
        : EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.description)))
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShowModal(null)
                popupDispatch({ type: "POPUP_END" });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickRef])

    const handleModal = () => {
        setShowModal(null)
        popupDispatch({ type: "POPUP_END" });
    }

    return (
        <div className="absolute top-0 left-0 z-10 p-3 w-screen min-h-screen overscroll-y-none overflow-y-hidden bg-black bg-opacity-70 flex items-center justify-center">
            <div ref={clickRef} className="p-3 w-11/12 lg:w-1/3 md:w-1/2 h-full bg-white rounded-md flex flex-col">
                <div className="px-2 flex justify-between items-center">
                    <h3 className='font-bold font-ssp text-xl md:text-2xl'>Detail Logs</h3>
                    <RiCloseFill onClick={handleModal} className='w-8 h-8 cursor-pointer'/>
                </div>
                <form className='mt-5 px-5 w-full flex flex-col'>
                    {/* employee */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            minLength={3} 
                            value={data.Obtained_Point_Log.firstname+ " " +data.Obtained_Point_Log.lastname+ " - " +data.Obtained_Point_Log.nip} 
                            type="text" 
                            name="employee" 
                            id="employee" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="employee" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Employee</label>
                    </div>
                    {/* admin */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            minLength={3} 
                            value={data.Approved_Point_Log.firstname+ " " +data.Approved_Point_Log.lastname} 
                            type="text" 
                            name="admin" 
                            id="admin" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="admin" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Admin</label>
                    </div>
                    {/* description */}
                    <div className="mt-3 mb-6 w-full relative z-0 group">
                        <label 
                            htmlFor="description" 
                            className="text-sm text-gray-500 duration-300 transform -translate-y-6 
                                        scale-75 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:font-medium 
                                        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Description</label>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="mt-2 border h-[500px] md:h-[300px] relative flex flex-col overflow-hidden border border-gray-300"
                            toolbarClassName="sticky"
                            editorClassName="px-2 overflow-y-scroll"
                        />
                    </div>
                    {/* type */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            minLength={3} 
                            value={data.type === 'revenue' ? "PENAMBAHAN" : "PENGURANGAN"} 
                            type="text" 
                            name="type" 
                            id="type" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="type" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Type</label>
                    </div>
                    {/* point */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            minLength={3} 
                            value={data.type === 'revenue' ? "+"+data.point : "-"+data.point} 
                            type="text" 
                            name="point" 
                            id="point" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="point" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Point</label>
                    </div>
                    {/* last_balance */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            minLength={3} 
                            value={data.last_balance} 
                            type="text" 
                            name="last_balance" 
                            id="last_balance" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="last_balance" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Total Point</label>
                    </div>
                    {/* time */}
                    <div className="relative z-0 mb-3 mt-3 w-full group">
                        <input 
                            minLength={3} 
                            value={dateTimeIndo(data.createdAt)} 
                            type="text" 
                            name="time" 
                            id="time" 
                            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                        appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                            placeholder=" " 
                            required readOnly
                        />
                        <label 
                            htmlFor="time" 
                            className="absolute top-3 -z-10 text-sm text-gray-500 dark:text-gray-400 
                                        duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium
                                        peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                                        peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >Waktu</label>
                    </div>
                    {/* Button */}
                    <div className="mb-6 mt-5 w-full relative z-0 flex gap-3 justify-end">
                        <button onClick={handleModal} 
                            className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg text-sm text-white
                                        text-center font-medium cursor-pointer"
                        >Close</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

ModalPointDetail.propTypes = {
    data: PropTypes.object,
    setShowModal: PropTypes.func,
}

export default ModalPointDetail