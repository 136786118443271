export const sidebarMenus = [
    {
        title: 'Dashboard', 
        icon: '/assets/icons/Dashboard.svg',
        link: '/',
        roles: ["admin", "superadmin", "event"],
        subMenus: []
    },
    {
        title: 'Offices', 
        icon: '/assets/icons/Shop.svg',
        link: '/offices',
        roles: ["superadmin"],
        subMenus: []
    },
    {
        title: 'News Update', 
        icon: '/assets/icons/News.svg',
        link: '/posts',
        roles: ["superadmin", "admin"],
        subMenus: []
    },
    {
        title: 'Employees', 
        icon: '/assets/icons/Employees.svg',
        link: '/employees',
        roles: ["superadmin", "admin"],
        subMenus: []
    },
    {
        title: 'Points', 
        icon: '/assets/icons/Audios.svg',
        link: '',
        roles: ["superadmin"],
        subMenus: [
            {
                subTitle: 'Rewards',
                link: '/rewards',
            },
            {
                subTitle: 'Log Rewards',
                link: '/log-rewards',
            },
            {
                subTitle: 'Log Points',
                link: '/points',
            },
        ]
    },
    {
        title: 'Eslon', 
        icon: '/assets/icons/Gallery.svg',
        link: '/echelons',
        roles: ["superadmin"],
        subMenus: []
    },
    {
        title: 'Position', 
        icon: '/assets/icons/Gallery.svg',
        link: '/positions',
        roles: ["superadmin"],
        subMenus: []
    },
]